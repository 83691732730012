import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Teaser from "../components/teaser"

export default class Stories extends React.Component {
  render() {
    return (
      <Layout title="Stories">
        <Seo title="Stories"/>
        <div className="container">
          <div className="row justify-content-center">
            <section className="col-12 col-sm-12 col-md-6 col-xl-4">
              <Teaser title="Bringing Love to My People" 
                      description={`"Anger was taking over my life. Hope was hard to come by, and I needed to find answers."`}
                      url="/stories/bringing-love-to-my-people"
                      thumbnail="/video-thumbnails/bringing-love-to-my-people.jpg"
                      author="Weston"
                      location="Arizona" />
            </section>
            <section className="col-12 col-sm-12 col-md-6 col-xl-4">
              <Teaser title="My Passion to Introduce Hope" 
                      description={`"I felt the same despair that so many of my people do. There's hope. You have more potential than you know."`}
                      url="/stories/my-passion-to-introduce-hope"
                      thumbnail="/video-thumbnails/my-passion-to-introduce-hope.jpg"
                      author="Anna"
                      location="Arizona" />
            </section>
            <section className="col-12 col-sm-12 col-md-6 col-xl-4">
              <Teaser title="From Ashes to a Crown of Beauty" 
                      description={`"I believed I was the only one that experienced sexual abuse. But I learned how to find hope and healing."`}
                      url="/stories/from-ashes-to-a-crown-of-beauty"
                      thumbnail="/video-thumbnails/from-ashes-to-a-crown-of-beauty.jpg"
                      author="Mary"
                      location="Alaska" />
            </section>                        
          </div>
        </div>
      </Layout>
    )
  }
}


